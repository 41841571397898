@import url('https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.4/css/bulma.min.css');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

body {
  background-image: url("./assets/pexels-james-wheeler-417074.jpg");
  background-size: cover;
  background-position: center;
}

/* Center the content vertically and horizontally */
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* Use a semi-transparent white box for the content */
.box {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem 3rem;
  border-radius: 0.5rem;
}

/* Use a custom font for the logo */
.logo {
  font-family: "Lobster", cursive;
  font-size: 3rem;
  color: #ff7b29;
}

/* Add some margin to the form elements */
.field,
.button {
  margin-top: 1rem;
}